<template>
  <div class="card-body mt-2 white">
    <div class="title">
      <i class="bi bi-menu-up"><span style="font-size: 14px; margin-left: 5px; color: black">รายละเอียด</span></i>
    </div>

    <div class="body-text mt-5">
      <!--begin::Row-->
      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">หัวข้อเรื่อง</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ appeals.title }} </span>
        </div>

        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">หัวข้อเรื่องย่อย</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ appeals.sub_title }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->


      <!--begin::Row-->
      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">รายละเอียด</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ appeals.detail }}</span>
        </div>
        <!--end::Col-->
      </div>

      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">เวลา</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ dateThai(appeals.event_date) }}</span>
        </div>
        <!--end::Col-->
      </div>

      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">สถานที่</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ appeals.event_place }}</span>
        </div>
        <!--end::Col-->
      </div>




      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">จังหวัด</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ appeals.event_province }}</span>
        </div>
        <!--end::Col-->
      </div>


      <!--end::Row-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Appeal-Detail-Detail",
  props: ["appeals"],
  setup() {


    function dateThai(date) {
      let y = moment(date).format("yyy");
      let year = parseInt(y) + 543;
      let march = moment(date).locale("th");

      return march.format("HH : mm : ss  วันที่ D MMMM " + year);
    }

    return {
      dateThai,
    };
  },
});

</script>

<style scoped></style>
